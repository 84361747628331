<template>
  <ui-component-modal
    modalTitle="Edit location data"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveLocation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-striped is-fullwidth">
        <tbody>
          <tr>
            <td>Name</td>
            <td>
              <input type="text" v-model="mLocation.Name" class="input" />
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              <input type="text" v-model="mLocation.Address" class="input" />
            </td>
          </tr>
          <tr>
            <td>Addition</td>
            <td>
              <input type="text" v-model="mLocation.Addition" class="input" />
            </td>
          </tr>
          <tr>
            <td>Postal code</td>
            <td>
              <input type="text" v-model="mLocation.Zipcode" class="input" />
            </td>
          </tr>
          <tr>
            <td>City</td>
            <td>
              <input type="text" v-model="mLocation.City" class="input" />
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td></td>
          </tr>
          <tr>
            <td>Latitude</td>
            <td>
              <input
                type="text"
                v-model.number="mLocation.Latitude"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Longitude</td>
            <td>
              <input
                type="text"
                v-model.number="mLocation.Longitude"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>
              <input
                type="text"
                v-model="mLocation.PhoneNumber"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <input type="text" v-model="mLocation.Email" class="input" />
            </td>
          </tr>
          <tr>
            <td>Website</td>
            <td><input type="text" v-model="mLocation.Url" class="input" /></td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import locationProvider from '@/providers/location'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mLocation: null,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {
    this.mLocation = JSON.parse(JSON.stringify(this.location))
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    saveLocation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        locationProvider.methods
          .saveLocationData(self.mLocation)
          .then((response) => {
            if (response.status === 200) {
              self.setLocation(self.mLocation)

              self.isSavingSuccess = true

              setTimeout(() => {
                self.onClickCancel()
              }, 1000)
            }
          })
          .catch((error) => {
            ////console.log(error.response)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
